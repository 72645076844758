// axiosConfig.js
import axios from "axios";
import { useNavigate } from "react-router-dom";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

let isRefreshing = false;
let subscribers = [];

function onTokenRefreshed(token) {
  subscribers.map((callback) => callback(token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;

    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        const refreshToken = localStorage.getItem("refreshToken");

        if (refreshToken) {
          return api
            .post("/api/auth/refresh-token", { token: refreshToken })
            .then(({ data }) => {
              const newToken = data.token;
              localStorage.setItem("token", newToken);
              isRefreshing = false;
              onTokenRefreshed(newToken);

              // Wiederhole die vorherige Anfrage mit dem neuen Token
              originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
              return api(originalRequest);
            })
            .catch((err) => {
              console.error("Error refreshing token:", err);
              localStorage.removeItem("token");
              localStorage.removeItem("refreshToken");
              window.location.href = "/login"; // Benutzer abmelden
              return Promise.reject(err);
            });
        } else {
          localStorage.removeItem("token");
          window.location.href = "/login"; // Benutzer abmelden
          return Promise.reject(error);
        }
      }

      return new Promise((resolve) => {
        addSubscriber((token) => {
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
          resolve(api(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  }
);

export default api;
