import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import api from "./axiosConfig";

const IntegrationCard = styled.div`
  width: 300px;
  padding: 20px;
  margin: 20px;
  background-color: #fff;
  border: 1px solid #ebedf0;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
`;

const CardLogo = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: 10px;
`;

const CardTitle = styled.h3`
  margin: 10px 0;
  font-size: 18px;
  color: #242745;
`;

const CardDescription = styled.p`
  font-size: 14px;
  color: #596780;
  margin-bottom: 20px;
`;

const ConnectButton = styled.button`
  background-color: ${(props) => (props.$connected ? "#ccc" : "#7367f0")};
  color: ${(props) => (props.$connected ? "#000000" : "#ffffff")};
  font-size: 14px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => (props.$connected ? "#ccc" : "#5e54e1")};
  }
`;

const ComingSoonOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  color: #7367f0;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;

const IntegrationsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const PopupContent = styled.div`
  width: 100%;
  max-width: 437px;
  height: 500px;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  @media (max-width: 480px) {
    width: calc(437px * 0.8);
    height: calc(420px * 0.8);
  }
`;

const PopupTitle = styled.h3`
  margin-bottom: 10px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #242745;
  text-align: center;
`;

const PopupText = styled.p`
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #242745;
  text-align: center;
  margin-bottom: 20px;
`;

const PopupInputLabel = styled.label`
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #596780;
  align-self: flex-start;
  margin-bottom: 5px;
`;

const PopupInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ebedf0;
  border-radius: 5px;
  font-size: 14px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
`;

const CancelButton = styled.button`
  background-color: #ffffff;
  color: #242745;
  font-size: 16px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans", sans-serif;
  border: 1px solid #242745;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const SubmitButton = styled.button`
  background-color: #6157c7;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans", sans-serif;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: #5147b3;
  }
`;

const IconRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  img {
    margin: 0 10px;
  }
`;

const IntegrationsSettings = ({ showPopup }) => {
  const [integrations, setIntegrations] = useState([
    {
      id: 1,
      logo: "/googlemybusiness.svg",
      title: "Google My Business",
      description:
        "Verbinden Sie Ihr Google My Business Konto, um Bewertungen zu verwalten und Geschäftsinformationen zu aktualisieren.",
      connected: false,
      comingSoon: false,
    },
    {
      id: 2,
      logo: "/googlemybusiness.svg",
      title: "Apple App Store",
      description:
        "Verbinden Sie Ihr Apple App Store Konto, um App-Informationen zu verwalten und Bewertungen zu importieren.",
      connected: false,
      comingSoon: true,
    },
    {
      id: 3,
      logo: "/googlemybusiness.svg",
      title: "Google Play Store",
      description:
        "Verbinden Sie Ihr Google Play Store Konto, um App-Informationen zu verwalten und Bewertungen zu importieren.",
      connected: false,
      comingSoon: true,
    },
  ]);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [currentIntegration, setCurrentIntegration] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchOAuthData = async () => {
      try {
        const updatedIntegrations = await Promise.all(
          integrations.map(async (integration) => {
            try {
              console.log(`Fetching data for ${integration.title}`);
              const res = await api.get(
                `/api/integrations/get-oauth-data/${encodeURIComponent(
                  integration.title
                )}`
              );
              console.log(`Fetched data for ${integration.title}:`, res.data);
              if (res.data) {
                return { ...integration, connected: true };
              }
            } catch (err) {
              console.error(
                `Error fetching data for ${integration.title}:`,
                err.response ? err.response.status : err.message
              );
            }
            return integration;
          })
        );
        setIntegrations(updatedIntegrations);
      } catch (err) {
        console.error("Error fetching OAuth data:", err);
      }
    };

    fetchOAuthData();
  }, []); // Empty dependency array ensures this effect runs only once

  const handleConnectClick = async (integration) => {
    setCurrentIntegration(integration);

    try {
      const res = await api.get(
        `/api/integrations/get-oauth-data/${encodeURIComponent(integration)}`
      );

      if (res.data) {
        setClientId(res.data.clientId);
        setClientSecret(res.data.clientSecret);
      }
    } catch (err) {
      console.error(`Error fetching data for ${integration}:`, err);
      setClientId("");
      setClientSecret("");
    }

    setIsPopupOpen(true);
  };

  const handlePopupSubmit = async () => {
    setIsLoading(true);
    try {
      console.log("Submitting:", {
        integration: currentIntegration,
        clientId,
        clientSecret,
      });
      const res = await api.post("/api/integrations/connect", {
        integration: currentIntegration,
        clientId,
        clientSecret,
      });

      if (res.data.url) {
        window.location.href = res.data.url;
      }

      const updatedIntegrations = integrations.map((integration) =>
        integration.title === currentIntegration
          ? { ...integration, connected: true }
          : integration
      );

      setIntegrations(updatedIntegrations);
      setIsPopupOpen(false);
      setClientId("");
      setClientSecret("");

      // Überprüfen, ob die Authentifizierung abgeschlossen ist
      if (currentIntegration === "Google My Business") {
        await checkAuthenticationAndLoadData();
      }
    } catch (error) {
      console.error("Error connecting to integration:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkAuthenticationAndLoadData = async () => {
    let isAuthenticated = false;
    while (!isAuthenticated) {
      try {
        const res = await api.get(
          `/api/integrations/get-oauth-data/${encodeURIComponent(
            currentIntegration
          )}`
        );
        if (res.data && res.data.accessToken) {
          isAuthenticated = true;
          await loadGMBData();
        }
      } catch (err) {
        console.error("Error checking authentication status:", err);
      }
      if (!isAuthenticated) {
        await new Promise((resolve) => setTimeout(resolve, 1000)); // 1 Sekunde warten
      }
    }
  };

  const loadGMBData = async () => {
    try {
      const res = await api.get("/api/integrations/load-gmb-data");
      console.log("Google My Business Data Loaded:", res.data);
    } catch (error) {
      console.error("Error loading Google My Business data:", error);
    }
  };

  return (
    <>
      <IntegrationsContainer>
        {integrations.map((integration) => (
          <IntegrationCard key={integration.id}>
            <CardLogo src={integration.logo} alt={integration.title} />
            <CardTitle>{integration.title}</CardTitle>
            <CardDescription>{integration.description}</CardDescription>
            <ConnectButton
              $connected={integration.connected}
              onClick={() => handleConnectClick(integration.title)}
            >
              {integration.connected ? "Update" : "Connect"}
            </ConnectButton>
            {integration.comingSoon && (
              <ComingSoonOverlay>Coming Soon</ComingSoonOverlay>
            )}
          </IntegrationCard>
        ))}
      </IntegrationsContainer>
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick
        onClose={() => setIsPopupOpen(false)}
        contentStyle={{
          width: "100%",
          maxWidth: "437px",
          height: "500px",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <PopupContent>
          <IconRow>
            <img src="/LogoBig.svg" alt="Main Logo" />
            <img src="/connect.svg" alt="Connect Logo" />
            <img src="/googlemybusiness.svg" alt="Google My Business Logo" />
          </IconRow>
          <PopupTitle>Connect to {currentIntegration}</PopupTitle>
          <PopupText>
            Respond to and track reviews from your Google My Business listing
            directly within RateSmart. Improve your online reputation and
            customer engagement.
          </PopupText>
          <PopupInputLabel>Client ID</PopupInputLabel>
          <PopupInput
            type="text"
            placeholder="Client ID"
            value={clientId}
            onChange={(e) => setClientId(e.target.value)}
          />
          <PopupInputLabel>Client Secret</PopupInputLabel>
          <PopupInput
            type="password"
            placeholder="Client Secret"
            value={clientSecret}
            onChange={(e) => setClientSecret(e.target.value)}
          />
          <ButtonGroup>
            <CancelButton onClick={() => setIsPopupOpen(false)}>
              Cancel
            </CancelButton>
            <SubmitButton onClick={handlePopupSubmit} disabled={isLoading}>
              {isLoading
                ? "Connecting..."
                : integrations.find(
                    (integration) => integration.title === currentIntegration
                  )?.connected
                ? "Update"
                : "Connect"}
            </SubmitButton>
          </ButtonGroup>
        </PopupContent>
      </Popup>
    </>
  );
};

export default IntegrationsSettings;
