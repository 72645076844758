import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const RegisterContainer = styled.div`
  display: flex;
  height: 100vh;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const ImageSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f9;
  overflow: hidden;
  @media (max-width: 768px) {
    display: none;
  }

  img {
    height: 100%;
    width: auto;
  }
`;

const FormSection = styled.div`
  width: 450px;
  padding: 0 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
    justify-content: center;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const Logo = styled.img`
  height: 50px;
  margin-right: 10px;
`;

const Title = styled.h1`
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #242745;
  font-weight: bold;
  font-size: 38px;
  margin: 0;
`;

const SubHeader = styled.h2`
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #242745;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #596780;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #727479;
  font-weight: medium;
  font-size: 16px;
  align-self: flex-start;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ebedf0;
  border-radius: 5px;
  &::placeholder {
    color: #c5c6c9 !important;
  }
`;

const PasswordContainer = styled.div`
  width: 100%;
  position: relative;
`;

const ShowPasswordIcon = styled.img`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-100%);
  cursor: pointer;
  height: 20px;
`;

const RegisterButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #7367f0;
  color: #fff;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  font-size: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
`;

const SignInText = styled.p`
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  & > a {
    color: #7367f0;
    font-weight: 500;
    text-decoration: none;
  }
`;

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    if (
      formData.email === "" ||
      formData.password === "" ||
      formData.confirmPassword === ""
    ) {
      alert("All fields are required");
      return;
    }

    try {
      await axios.post(process.env.REACT_APP_API_URL+"api/auth/register", {
        email: formData.email,
        password: formData.password,
        password2: formData.confirmPassword,
      });
      navigate("/login"); // Weiterleitung zur Login-Seite nach erfolgreicher Registrierung
    } catch (err) {
      const errorMessage = err.response?.data || "Error registering user";
      console.error(errorMessage);
      alert(errorMessage);
    }
  };

  return (
    <RegisterContainer>
      <ImageSection>
        <img src="/Login.svg" alt="Illustration" />
      </ImageSection>
      <FormSection>
        <LogoContainer>
          <Logo src="/Logo.svg" alt="Logo" />
          <Title>RateSmart</Title>
        </LogoContainer>
        <SubHeader>Welcome to Ratesmart! 👋🏻</SubHeader>
        <Description>
          Please sign-up to create an account and start the adventure
        </Description>
        <form onSubmit={onSubmit}>
          <Label>Email</Label>
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={onChange}
            placeholder="yourname@example.com"
            required
          />
          <Label>Password</Label>
          <PasswordContainer>
            <Input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={onChange}
              placeholder="••••••••"
              required
            />
            <ShowPasswordIcon
              src="/eye.png"
              alt="Show password"
              onClick={() => setShowPassword(!showPassword)}
            />
          </PasswordContainer>
          <Label>Confirm Password</Label>
          <PasswordContainer>
            <Input
              type={showPassword ? "text" : "password"}
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={onChange}
              placeholder="••••••••"
              required
            />
            <ShowPasswordIcon
              src="/eye.png"
              alt="Show password"
              onClick={() => setShowPassword(!showPassword)}
            />
          </PasswordContainer>
          <RegisterButton type="submit">REGISTER</RegisterButton>
        </form>
        <SignInText>
          Already have an account? <Link to="/login">Sign in</Link>
        </SignInText>
      </FormSection>
    </RegisterContainer>
  );
};

export default Register;
