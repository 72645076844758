import React from "react";

const NotificationsSettings = () => {
  return (
    <div>
      <h2>Notifications Settings</h2>
      <p>Manage your notification preferences here.</p>
    </div>
  );
};

export default NotificationsSettings;
