// src/components/GlobalStyle.js
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }

  * {
    box-sizing: border-box;
  }
`;

export default GlobalStyle;
