import React from "react";

const AccountSettings = () => {
  return (
    <div>
      <h2>Account Settings</h2>
      <p>Manage your account information and settings here.</p>
    </div>
  );
};

export default AccountSettings;
