import React from "react";

const BillingSettings = () => {
  return (
    <div>
      <h2>Billing & Plans</h2>
      <p>Manage your billing information and plans here.</p>
    </div>
  );
};

export default BillingSettings;
