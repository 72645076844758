import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login";
import Register from "./components/Register";
import ForgotPassword from "./components/ForgotPassword";
import ReviewManager from "./components/ReviewManager";
import Settings from "./components/Settings";
import Sidebar from "./components/Sidebar";
import GlobalStyle from "./components/GlobalStyle"; // Korrigierter Pfad
import useTokenRenewal from "./hooks/useTokenRenewal"; // Importiere den Hook

const App = () => {
  useTokenRenewal(); // Verwende den Hook hier

  const isAuthenticated = () => {
    return !!localStorage.getItem("token");
  };

  const ProtectedRoute = ({ element }) => {
    return isAuthenticated() ? element : <Navigate to="/login" />;
  };

  const WithSidebar = ({ element }) => (
    <>
      <Sidebar />
      {element}
    </>
  );

  return (
    <Router>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/review-manager"
          element={
            <ProtectedRoute
              element={<WithSidebar element={<ReviewManager />} />}
            />
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute element={<WithSidebar element={<Settings />} />} />
          }
        />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;
