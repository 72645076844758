import React, { useEffect, useState, useRef, useCallback } from "react";
import styled from "styled-components";
import { FaFilter, FaCaretDown, FaStar } from "react-icons/fa";

const calculateAverageRating = (reviews) => {
  if (reviews.length === 0) return 0;
  const total = reviews.reduce((sum, review) => sum + review.starRating, 0);
  return (total / reviews.length).toFixed(2); // auf eine Dezimalstelle gerundet
};

const calculateRatingFrequency = (reviews) => {
  const frequencies = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
  reviews.forEach((review) => {
    frequencies[review.starRating] += 1;
  });
  return frequencies;
};

const HeaderSection = styled.div`
  display: flex;
  margin: 50px 20px 20px 20px;
  background-color: #fff; // dunkelgrauer Hintergrund

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    margin-left: 0;
    width: 100%;
    padding-bottom: 20px;
  }
`;

const ModuleContainer = styled.div`
  display: flex;
  width: 100%;
  margin-left: 250px;

  @media (max-width: 1024px) {
    margin-left: 0px;
  }

  @media (max-width: 600px) {
    // Hier die Breite anpassen, bei der die Module untereinander angeordnet werden sollen
    flex-direction: column;
    align-items: center;
    margin-left: 0px;
  }
`;

const ImageModule = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  max-width: 300px;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 10px;
  }
`;

const RatingsModule = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px; // Stellt sicher, dass das Modul niemals kleiner als 300px wird
  box-sizing: border-box;
  padding: 20px;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 20px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 300px;
  height: 200px;
`;

const Image1 = styled.img`
  position: absolute;
  top: 0;
  margin-top: 50px;
  left: 0;
  z-index: 1; // Bild 1 hat einen niedrigeren Z-Index
  width: 300px; // Breite des Containers, anpassen je nach Bildgröße
  height: 300px;
`;

const Image2 = styled.img`
  position: absolute;
  top: 0;
  margin-top: 50px;
  left: 0;
  z-index: 2;
  width: 300px; // Breite des Containers, anpassen je nach Bildgröße
  height: 300px;
  transform: rotate(var(--base-rotation)); // Basisrotation
  animation: slightRotate 2s infinite ease-in-out; // leichte Rotationsanimation

  @keyframes slightRotate {
    0% {
      transform: rotate(calc(var(--base-rotation) - 2deg));
    }
    50% {
      transform: rotate(calc(var(--base-rotation) + 2deg));
    }
    100% {
      transform: rotate(calc(var(--base-rotation) - 2deg));
    }
  }
`;

const AverageRatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
  margin-left: 0px;
`;

const AverageRating = styled.div`
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 32px;
  font-weight: bold;
  color: #000; // Farbe der Zahl
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

const StarIcon = styled(FaStar)`
  color: #ffcc00; // gelbe Farbe
  margin-left: 10px;
  font-size: 36px; // Größe des Sterns anpassen
`;

const RatingFrequencyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 25px;
`;

const RatingRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  width: 100%;

  @media (max-width: 1024px) {
    margin-bottom: 25px;
  }
  @media (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const RatingText = styled.div`
  width: 50px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 12px;
  font-weight: 800;
  color: #242745;
`;

const StatusBarContainer = styled.div`
  flex: 1;
  height: 12px;
  background-color: #d9d9d9;
  border-radius: 6px;
  margin: 0 10px;
  position: relative;
`;

const StatusBar = styled.div`
  height: 100%;
  background-color: #6157c7;
  border-radius: 6px;
  width: ${(props) => props.width}%;
`;

const ReviewContainer = styled.div`
  padding: 0px 20px 200px 20px;
  margin-left: 230px;
  box-sizing: border-box;
  overflow-x: auto;
  height: 100%; /* Ensure container takes full height */

  @media (max-width: 1024px) {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #ffffff;
  font-size: 14px;
  height: 100%; /* Ensure table takes full height */

  @media (max-width: 768px) {
    display: none;
  }
`;

const Th = styled.th`
  border-bottom: 1px solid #ebedf0;
  padding: 8px;
  background-color: #f5f6f7;
  font-weight: bold;
  color: #32393c;
  text-align: center;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
`;

const Td = styled.td`
  border-bottom: 2px solid #e1e1e1;
  padding: 8px;
  color: #242745;
  text-align: center;
  vertical-align: middle;
  min-height: 250px;
  position: relative;
  vertical-align: top; /* Ensure textarea starts at the top */
`;

const ReviewTd = styled.td`
  text-align: justify;
  min-height: 200px; /* Mindesthöhe von 200px */
  vertical-align: top; /* Ensure textarea starts at the top */
  margin-bottom: 25px;
  border-bottom: 2px solid #e1e1e1;
  padding: 8px;
`;

const ResponseTd = styled.td`
  text-align: justify;
  min-height: 250px; /* Mindesthöhe von 200px */
  padding: 0; /* Keine zusätzliche Polsterung */
  vertical-align: top; /* Ensure textarea starts at the top */
  flex-direction: column;
  height: 100%; /* Ensure td takes full height */
  border-bottom: 2px solid #e1e1e1;
  padding: 8px;
`;

const ResponseTextarea = styled.textarea`
  font-size: 14px;
  color: #242745;
  border-bottom: 2px solid #e1e1e1;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 100%; /* Ensure textarea takes full width */
  height: 100%; /* Ensure textarea takes full height */
  box-sizing: border-box;
  resize: none;
  border: 1px solid #ebedf0;
  border-radius: 4px;
  padding: 8px;
  background-color: #f9f9f9;
  overflow-y: auto;
  min-height: 200px; /* Ensure a minimum height of 200px */

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #a1a1a1;
  }
`;

const PaginationBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
    order: 2;
  }
`;

const CardSection = styled.div`
  margin-bottom: 10px;
`;

const StatusCircle = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  display: inline-block;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    opacity: 0.3;
    animation: pulsate 2s infinite;
  }

  &::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
  }
`;

const RespondButton = styled.button`
  background-color: ${(props) => (props.disabled ? "#d3d3d3" : "#7367f0")};
  color: ${(props) => (props.disabled ? "#000" : "#fff")};
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  padding: 8px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

const PaginationText = styled.span`
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  color: rgba(76, 78, 100, 0.75);
  margin-right: 10px;
`;

const PaginationNumber = styled.span`
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  color: #1a202c;
  margin-right: 10px;
  cursor: pointer;
`;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
`;

const DropdownContent = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: absolute;
  background-color: white;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
`;

const DropdownOption = styled.div`
  padding: 8px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const FilterIcon = styled(FaFilter)`
  margin-left: 5px;
  cursor: pointer;
  color: rgba(76, 78, 100, 0.75);
`;

const CaretDownIcon = styled(FaCaretDown)`
  margin-left: 5px;
  cursor: pointer;
  color: rgba(76, 78, 100, 0.75);
`;

const NavigationButton = styled.span`
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  cursor: pointer;
  margin-left: 20px;
  font-weight: bold;
  color: ${(props) => (props.disabled ? "rgba(76, 78, 100, 0.75)" : "#1a202c")};
`;

const ReviewCardContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
`;

const ReviewCard = styled.div`
  background-color: #ffffff;
  color: #242745;
  padding: 10px;
  text-align: justify;
  font-size: 16px;
  border: 1px solid #ebedf0;
  border-radius: 8px;
  margin-bottom: 20px;

  @media (min-width: 769px) {
    display: none;
  }
`;

const MobileFilterBar = styled.div`
  display: none;
  width: 100%;
  padding: 10px;
  background-color: #f5f6f7;
  border: 1px solid #ebedf0;
  border-radius: 8px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const MobileDropdown = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
`;

const MobileDropdownContent = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: absolute;
  background-color: white;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const MobileDropdownOption = styled.div`
  padding: 8px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const ReviewManager = () => {
  const [reviews, setReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewsPerPage, setReviewsPerPage] = useState(5);
  const [filters, setFilters] = useState({
    rating: null,
    review: null,
    status: null,
  });
  const [dropdownVisible, setDropdownVisible] = useState({
    rating: false,
    review: false,
    status: false,
    reviewsPerPage: false,
    mobileRating: false,
    mobileReview: false,
    mobileStatus: false,
  });

  const ratingRef = useRef(null);
  const reviewRef = useRef(null);
  const statusRef = useRef(null);
  const reviewsPerPageRef = useRef(null);
  const mobileRatingRef = useRef(null);
  const mobileReviewRef = useRef(null);
  const mobileStatusRef = useRef(null);
  const typingTimeoutRef = useRef(null);
  const isTypingRef = useRef(false);

  const fetchReviews = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(process.env.REACT_APP_API_URL+"api/reviews", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok. token: " + token);
      }
      const data = await response.json();
      const sortedData = data.sort(
        (a, b) => new Date(b.createTime) - new Date(a.createTime)
      );
      setReviews(sortedData);
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  useEffect(() => {
    const fetchReviewsPeriodically = async () => {
      await fetchReviews();
    };

    fetchReviewsPeriodically(); // Initial fetch
    const intervalId = setInterval(fetchReviewsPeriodically, 2000); // Fetch every 2 seconds

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, []);

  useEffect(() => {
    const averageRating = calculateAverageRating(reviews);
    const rotation = getRotation(averageRating);
    document.documentElement.style.setProperty(
      "--base-rotation",
      `${rotation}deg`
    );
  }, [reviews]);

  const getRotation = (rating) => {
    return (rating - 2.5) * 36; // Berechnung des Rotationswinkels
  };

  const handleTextareaChange = (e, reviewId) => {
    clearTimeout(typingTimeoutRef.current);
    isTypingRef.current = true;

    const updatedText = e.target.value;
    setReviews((prevReviews) =>
      prevReviews.map((review) =>
        review._id === reviewId
          ? { ...review, aiResponse: updatedText }
          : review
      )
    );
    typingTimeoutRef.current = setTimeout(() => {
      isTypingRef.current = false;
      saveAiResponse(reviewId, updatedText);
    }, 2000); // Delay of 2 seconds after user stops typing
  };

  const saveAiResponse = async (reviewId, aiResponse) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/reviews/${reviewId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ aiResponse }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save AI response");
      }
    } catch (error) {
      console.error("Error saving AI response:", error);
    }
  };

  const handleRespondClick = async (reviewId) => {
    try {
      const reviewToUpdate = reviews.find((review) => review._id === reviewId);
      const updatedReview = {
        ...reviewToUpdate,
        reviewReply: {
          comment: reviewToUpdate.aiResponse,
          updateTime: new Date(),
        },
        status: "responded",
      };

      const token = localStorage.getItem("token");

      // Log the payload before sending
      console.log("Sending payload:", {
        comment: updatedReview.reviewReply.comment,
        aiResponse: updatedReview.aiResponse,
      });

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/reviews/${reviewId}/respond`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            comment: updatedReview.reviewReply.comment,
            aiResponse: updatedReview.aiResponse,
          }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text(); // Get response text for additional error details
        console.error("Response error:", errorText);
        throw new Error("Failed to update review status");
      }

      const result = await response.json();

      setReviews((prevReviews) => {
        const existingReviewIndex = prevReviews.findIndex(
          (review) => review._id === result._id
        );
        if (existingReviewIndex !== -1) {
          const updatedReviews = [...prevReviews];
          updatedReviews[existingReviewIndex] = result;
          return updatedReviews.sort(
            (a, b) => new Date(b.createTime) - new Date(a.createTime)
          );
        } else {
          return [result, ...prevReviews].sort(
            (a, b) => new Date(b.createTime) - new Date(a.createTime)
          );
        }
      });
    } catch (error) {
      console.error("Error responding to review:", error);
    }
  };

  const handleReviewsPerPageChange = (number) => {
    setReviewsPerPage(number);
    setCurrentPage(1);
    setDropdownVisible((prevState) => ({
      ...prevState,
      reviewsPerPage: false,
    }));
  };

  const handleFilterChange = (type, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value,
    }));
    setCurrentPage(1);
    setDropdownVisible((prevState) => ({
      ...prevState,
      [type]: false,
      [`mobile${type.charAt(0).toUpperCase() + type.slice(1)}`]: false,
    }));
  };

  const clearFilter = (type) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: null,
    }));
    setCurrentPage(1);
    setDropdownVisible((prevState) => ({
      ...prevState,
      [type]: false,
      [`mobile${type.charAt(0).toUpperCase() + type.slice(1)}`]: false,
    }));
  };

  const filteredReviews = reviews.filter((review) => {
    if (filters.rating && review.starRating !== filters.rating) {
      return false;
    }
    if (filters.review === "with" && !review.comment) {
      return false;
    }
    if (filters.review === "without" && review.comment) {
      return false;
    }
    if (filters.status && review.status !== filters.status) {
      return false;
    }
    return true;
  });

  const totalPages = Math.ceil(filteredReviews.length / reviewsPerPage);
  const startIndex = (currentPage - 1) * reviewsPerPage;
  const endIndex = startIndex + reviewsPerPage;
  const currentReviews = filteredReviews.slice(startIndex, endIndex);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const averageRating = calculateAverageRating(reviews);
  const ratingFrequencies = calculateRatingFrequency(reviews);
  const maxFrequency = Math.max(...Object.values(ratingFrequencies));

  const renderStars = (rating) => {
    const totalStars = 5;
    return Array.from({ length: totalStars }, (_, i) => (
      <span key={i} style={{ color: i < rating ? "#ffcc00" : "#D9D9D9" }}>
        &#9733;
      </span>
    ));
  };

  const renderStatus = (status) => {
    const statusColors = {
      responded: "#7dcc3f",
      pending: "#ffcc00",
      "not-responded": "#ff4423",
      "never-answer": "#000000",
    };
    return <StatusCircle color={statusColors[status] || "#d3d3d3"} />;
  };

  const toggleDropdown = (type) => {
    setDropdownVisible((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  const handleOutsideClick = useCallback((e) => {
    if (
      ratingRef.current &&
      !ratingRef.current.contains(e.target) &&
      reviewRef.current &&
      !reviewRef.current.contains(e.target) &&
      statusRef.current &&
      !statusRef.current.contains(e.target) &&
      reviewsPerPageRef.current &&
      !reviewsPerPageRef.current.contains(e.target) &&
      mobileRatingRef.current &&
      !mobileRatingRef.current.contains(e.target) &&
      mobileReviewRef.current &&
      !mobileReviewRef.current.contains(e.target) &&
      mobileStatusRef.current &&
      !mobileStatusRef.current.contains(e.target)
    ) {
      setDropdownVisible({
        rating: false,
        review: false,
        status: false,
        reviewsPerPage: false,
        mobileRating: false,
        mobileReview: false,
        mobileStatus: false,
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <>
      <HeaderSection>
        <ModuleContainer>
          <ImageModule>
            <ImageContainer>
              <Image1 src="/good-feedback1.png" alt="Good Feedback 1" />
              <Image2 src="/good-feedback2.png" alt="Good Feedback 2" />
              <AverageRatingContainer>
                <AverageRating>
                  {averageRating}
                  <StarIcon />
                </AverageRating>
              </AverageRatingContainer>
            </ImageContainer>
          </ImageModule>
          <RatingsModule>
            <RatingFrequencyContainer>
              {[5, 4, 3, 2, 1].map((rating) => (
                <RatingRow key={rating}>
                  <RatingText>{rating} Star</RatingText>
                  <StatusBarContainer>
                    <StatusBar
                      width={(ratingFrequencies[rating] / maxFrequency) * 100}
                    />
                  </StatusBarContainer>
                  <RatingText>{ratingFrequencies[rating]}</RatingText>
                </RatingRow>
              ))}
            </RatingFrequencyContainer>
          </RatingsModule>
        </ModuleContainer>
      </HeaderSection>
      <ReviewContainer>
        <Table>
          <thead>
            <tr>
              <Th style={{ width: "75px" }}>Date</Th>
              <Th style={{ width: "85px" }}>StoreCode</Th>
              <Th style={{ width: "170px" }}>Location</Th>
              <Th
                style={{ width: "75px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDropdown("rating");
                }}
              >
                Rating
                <FilterIcon />
                <DropdownContent show={dropdownVisible.rating} ref={ratingRef}>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <DropdownOption
                      key={star}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleFilterChange("rating", star);
                      }}
                    >
                      {star} Stars
                    </DropdownOption>
                  ))}
                  <DropdownOption
                    onClick={(e) => {
                      e.stopPropagation();
                      clearFilter("rating");
                    }}
                  >
                    Clear Filter
                  </DropdownOption>
                </DropdownContent>
              </Th>
              <Th style={{ width: "85px" }}>Reviewer</Th>
              <Th
                style={{ width: "20%" }}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDropdown("review");
                }}
              >
                Review
                <FilterIcon />
                <DropdownContent show={dropdownVisible.review} ref={reviewRef}>
                  <DropdownOption
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFilterChange("review", "with");
                    }}
                  >
                    With Text
                  </DropdownOption>
                  <DropdownOption
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFilterChange("review", "without");
                    }}
                  >
                    Without Text
                  </DropdownOption>
                  <DropdownOption
                    onClick={(e) => {
                      e.stopPropagation();
                      clearFilter("review");
                    }}
                  >
                    Clear Filter
                  </DropdownOption>
                </DropdownContent>
              </Th>
              <Th style={{ width: "20%" }}>Response</Th>
              <Th
                style={{ width: "55px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDropdown("status");
                }}
              >
                Status
                <FilterIcon />
                <DropdownContent show={dropdownVisible.status} ref={statusRef}>
                  <DropdownOption
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFilterChange("status", "responded");
                    }}
                  >
                    Responded
                  </DropdownOption>
                  <DropdownOption
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFilterChange("status", "pending");
                    }}
                  >
                    Pending
                  </DropdownOption>
                  <DropdownOption
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFilterChange("status", "not-responded");
                    }}
                  >
                    Not Responded
                  </DropdownOption>
                  <DropdownOption
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFilterChange("status", "never-answer");
                    }}
                  >
                    Never Answer
                  </DropdownOption>
                  <DropdownOption
                    onClick={(e) => {
                      e.stopPropagation();
                      clearFilter("status");
                    }}
                  >
                    Clear Filter
                  </DropdownOption>
                </DropdownContent>
              </Th>
              <Th style={{ width: "100px" }}>Action</Th>
            </tr>
          </thead>
          <tbody>
            {currentReviews.map((review) => (
              <tr key={review._id}>
                <Td style={{ width: "75px" }}>
                  {new Date(review.createTime).toLocaleString()}
                </Td>
                <Td style={{ width: "85px" }}>{review.storeCode}</Td>
                <Td style={{ width: "170px" }}>
                  {review.storefrontAddress}
                </Td>
                <Td style={{ width: "75px" }}>
                  {renderStars(review.starRating)}
                </Td>
                <Td style={{ width: "85px" }}>{review.reviewerName}</Td>
                <ReviewTd style={{ width: "20%" }}>{review.comment}</ReviewTd>
                <ResponseTd style={{ width: "20%" }}>
                  <ResponseTextarea
                    className="response-textarea"
                    value={
                      review.reviewReply
                        ? review.reviewReply.comment
                        : review.aiResponse
                    }
                    rows={4}
                    onChange={(e) => handleTextareaChange(e, review._id)}
                  />
                </ResponseTd>
                <Td style={{ width: "55px" }}>{renderStatus(review.status)}</Td>
                <Td style={{ width: "100px" }}>
                  <RespondButton
                    disabled={review.status === "never-answer"}
                    onClick={() => handleRespondClick(review._id)}
                  >
                    Respond
                  </RespondButton>
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
        <PaginationBar>
          <PaginationText>Reviews per Page:</PaginationText>
          <Dropdown
            onClick={(e) => {
              e.stopPropagation();
              toggleDropdown("reviewsPerPage");
            }}
            ref={reviewsPerPageRef}
          >
            <PaginationNumber>
              {reviewsPerPage}
              <CaretDownIcon />
            </PaginationNumber>
            <DropdownContent show={dropdownVisible.reviewsPerPage}>
              {[5, 10, 25, 50, 100].map((number) => (
                <DropdownOption
                  key={number}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleReviewsPerPageChange(number);
                  }}
                >
                  {number}
                </DropdownOption>
              ))}
            </DropdownContent>
          </Dropdown>
          <PaginationText>
            {startIndex + 1}-{Math.min(endIndex, filteredReviews.length)} of{" "}
            {filteredReviews.length}
          </PaginationText>
          <NavigationButton
            disabled={currentPage === 1}
            onClick={handlePreviousPage}
          >
            &lt;
          </NavigationButton>
          <NavigationButton
            disabled={currentPage === totalPages}
            onClick={handleNextPage}
          >
            &gt;
          </NavigationButton>
        </PaginationBar>
        <MobileFilterBar>
          <MobileDropdown
            onClick={(e) => {
              e.stopPropagation();
              toggleDropdown("mobileRating");
            }}
            ref={mobileRatingRef}
          >
            Rating
            <CaretDownIcon />
            <MobileDropdownContent show={dropdownVisible.mobileRating}>
              {[1, 2, 3, 4, 5].map((star) => (
                <MobileDropdownOption
                  key={star}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFilterChange("rating", star);
                  }}
                >
                  {star} Stars
                </MobileDropdownOption>
              ))}
              <MobileDropdownOption
                onClick={(e) => {
                  e.stopPropagation();
                  clearFilter("rating");
                }}
              >
                Clear Filter
              </MobileDropdownOption>
            </MobileDropdownContent>
          </MobileDropdown>
          <MobileDropdown
            onClick={(e) => {
              e.stopPropagation();
              toggleDropdown("mobileReview");
            }}
            ref={mobileReviewRef}
          >
            Review
            <CaretDownIcon />
            <MobileDropdownContent show={dropdownVisible.mobileReview}>
              {[
                { text: "With Text", value: "with" },
                { text: "Without Text", value: "without" },
              ].map((option) => (
                <MobileDropdownOption
                  key={option.value}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFilterChange("review", option.value);
                  }}
                >
                  {option.text}
                </MobileDropdownOption>
              ))}
              <MobileDropdownOption
                onClick={(e) => {
                  e.stopPropagation();
                  clearFilter("review");
                }}
              >
                Clear Filter
              </MobileDropdownOption>
            </MobileDropdownContent>
          </MobileDropdown>
          <MobileDropdown
            onClick={(e) => {
              e.stopPropagation();
              toggleDropdown("mobileStatus");
            }}
            ref={mobileStatusRef}
          >
            Status
            <CaretDownIcon />
            <MobileDropdownContent show={dropdownVisible.mobileStatus}>
              {[
                { text: "Responded", value: "responded" },
                { text: "Pending", value: "pending" },
                { text: "Not Responded", value: "not-responded" },
                { text: "Never Answer", value: "never-answer" },
              ].map((option) => (
                <MobileDropdownOption
                  key={option.value}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFilterChange("status", option.value);
                  }}
                >
                  {option.text}
                </MobileDropdownOption>
              ))}
              <MobileDropdownOption
                onClick={(e) => {
                  e.stopPropagation();
                  clearFilter("status");
                }}
              >
                Clear Filter
              </MobileDropdownOption>
            </MobileDropdownContent>
          </MobileDropdown>
        </MobileFilterBar>
        <ReviewCardContainer>
          {currentReviews.map((review) => (
            <ReviewCard key={review._id}>
              <CardSection>
                <strong>Date:</strong>{" "}
                {new Date(review.createTime).toLocaleString()}
              </CardSection>
              <CardSection>
                <strong>StoreCode:</strong> {review.storeCode}
              </CardSection>
              <CardSection>
                <strong>Location:</strong> {review.locationName}
              </CardSection>
              <CardSection>
                <strong>Rating:</strong> {renderStars(review.starRating)}
              </CardSection>
              <CardSection>
                <strong>Reviewer:</strong> {review.reviewerName}
              </CardSection>
              <CardSection>
                <strong>Review:</strong> {review.comment}
              </CardSection>
              <CardSection>
                <strong>Response:</strong>
                <ResponseTextarea
                  className="response-textarea"
                  value={
                    review.reviewReply
                      ? review.reviewReply.comment
                      : review.aiResponse
                  }
                  rows={4}
                  onChange={(e) => handleTextareaChange(e, review._id)}
                />
              </CardSection>
              <CardSection>
                <strong>Status:</strong> {renderStatus(review.status)}
              </CardSection>
              <CardSection>
                <RespondButton
                  disabled={review.status === "never-answer"}
                  onClick={() => handleRespondClick(review._id)}
                >
                  Respond
                </RespondButton>
              </CardSection>
            </ReviewCard>
          ))}
        </ReviewCardContainer>
      </ReviewContainer>
    </>
  );
};

export default ReviewManager;
