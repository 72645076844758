import { useEffect } from "react";
import api from "../components/axiosConfig";

const useTokenRenewal = () => {
  useEffect(() => {
    const renewToken = async () => {
      try {
        const refreshToken = localStorage.getItem("refreshToken");
        if (refreshToken) {
          const { data } = await api.post("/api/auth/refresh-token", {
            token: refreshToken,
          });
          localStorage.setItem("token", data.token);
        }
      } catch (err) {
        console.error("Error renewing token:", err);
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        window.location.href = "/login"; // Benutzer abmelden
      }
    };

    const intervalId = setInterval(() => {
      const token = localStorage.getItem("token");
      if (token) {
        const tokenExpirationTime = JSON.parse(atob(token.split(".")[1])).exp;
        const currentTime = Math.floor(Date.now() / 1000);

        // Erneuere den Token, wenn er in weniger als 5 Minuten abläuft
        if (tokenExpirationTime - currentTime < 300) {
          renewToken();
        }
      }
    }, 60000); // Überprüfe alle 60 Sekunden

    return () => clearInterval(intervalId);
  }, []);
};

export default useTokenRenewal;
