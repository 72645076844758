import React, { useState } from "react";
import styled from "styled-components";
import { FaUser, FaLock, FaCreditCard, FaBell, FaLink } from "react-icons/fa";
import AccountSettings from "./AccountSettings";
import SecuritySettings from "./SecuritySettings";
import BillingSettings from "./BillingSettings";
import NotificationsSettings from "./NotificationsSettings";
import IntegrationsSettings from "./IntegrationsSettings";

const SettingsContainer = styled.div`
  padding: 50px 20px 20px 20px;
  margin-left: 230px; /* Platz für die Sidebar */
  box-sizing: border-box;
  overflow-x: auto;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
`;

const Topbar = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-around;
  background-color: #f3f5f7;
  box-sizing: border-box;
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #f3f5f7;
  color: #242745;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &.active {
    background-color: #7c5cfc;
    color: #ffffff;
  }

  &:hover {
    background-color: #e1e3e7;
    color: #242745;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }

  svg {
    margin-right: 10px;
    @media (max-width: 768px) {
      margin-right: 0;
    }
  }
`;

const NavText = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ebedf0;
  border-radius: 8px;
  margin-top: 20px;
`;

const Settings = () => {
  const [activeTab, setActiveTab] = useState("account");

  const renderContent = () => {
    switch (activeTab) {
      case "account":
        return <AccountSettings />;
      case "security":
        return <SecuritySettings />;
      case "billing":
        return <BillingSettings />;
      case "notifications":
        return <NotificationsSettings />;
      case "integrations":
        return <IntegrationsSettings />;
      default:
        return <AccountSettings />;
    }
  };

  return (
    <SettingsContainer>
      <Topbar>
        <NavItem
          className={activeTab === "account" ? "active" : ""}
          onClick={() => setActiveTab("account")}
        >
          <FaUser />
          <NavText>Account</NavText>
        </NavItem>
        <NavItem
          className={activeTab === "security" ? "active" : ""}
          onClick={() => setActiveTab("security")}
        >
          <FaLock />
          <NavText>Security</NavText>
        </NavItem>
        <NavItem
          className={activeTab === "billing" ? "active" : ""}
          onClick={() => setActiveTab("billing")}
        >
          <FaCreditCard />
          <NavText>Billing & Plans</NavText>
        </NavItem>
        <NavItem
          className={activeTab === "notifications" ? "active" : ""}
          onClick={() => setActiveTab("notifications")}
        >
          <FaBell />
          <NavText>Notifications</NavText>
        </NavItem>
        <NavItem
          className={activeTab === "integrations" ? "active" : ""}
          onClick={() => setActiveTab("integrations")}
        >
          <FaLink />
          <NavText>Integrations</NavText>
        </NavItem>
      </Topbar>
      <Content>{renderContent()}</Content>
    </SettingsContainer>
  );
};

export default Settings;
