import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";
import {
  FaTachometerAlt,
  FaStar,
  FaCog,
  FaSignOutAlt,
  FaBars,
} from "react-icons/fa";

const SidebarContainer = styled.div`
  width: ${(props) => (props.$isOpen ? "230px" : "0")};
  height: 100vh;
  background-color: #ffffff;
  padding: ${(props) => (props.$isOpen ? "20px" : "0")};
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  transition: width 0.3s ease, padding 0.3s ease;
  overflow: hidden;
  z-index: 1000;

  @media (max-width: 768px) {
    width: ${(props) => (props.$isOpen ? "100%" : "0")};
  }
`;

const ToggleButton = styled.button`
  display: none;
  position: fixed;
  top: 20px;
  left: ${(props) => (props.$isOpen ? "250px" : "20px")};
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1100;
  color: #242745;

  @media (max-width: 1024px) {
    display: block;
    left: ${(props) => (props.$isOpen ? "250px" : "20px")};
  }

  @media (max-width: 768px) {
    left: ${(props) => (props.$isOpen ? "calc(100% - 40px)" : "20px")};
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 128px;
  color: #242745;
  font-size: 28px;
  font-weight: bold;
  font-family: "Plus Jakarta Sans", sans-serif;
`;

const Logo = styled.img`
  height: 50px;
  margin-right: 10px;
`;

const Menu = styled.div`
  margin-top: 128px;
`;

const MenuTitle = styled.div`
  font-size: 14px;
  color: #90a3bf;
  margin-bottom: 10px;
`;

const NavItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #f3f5f7;
  color: #242745;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  font-family: "Plus Jakarta Sans", sans-serif;
  cursor: pointer;
  position: relative; /* Wichtig für das Overlay */

  &.active {
    background-color: #7c5cfc;
    color: #ffffff;

    &:hover {
      background-color: #6359cb;
      color: #ffffff;
    }
  }

  &:hover {
    background-color: #e1e3e7;
    color: #242745;
  }

  svg {
    margin-right: 10px;
  }
`;

const ComingSoonOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  color: #7367f0;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;

const ComingSoonMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #f3f5f7;
  color: #242745;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  font-family: "Plus Jakarta Sans", sans-serif;
  cursor: default;
  position: relative; /* Wichtig für das Overlay */

  &:hover {
    background-color: #e1e3e7;
    color: #242745;
  }

  svg {
    margin-right: 10px;
  }
`;

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 1024) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };

    handleResize(); // Set initial state
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <ToggleButton $isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <FaBars />
      </ToggleButton>
      <SidebarContainer $isOpen={isOpen}>
        <LogoContainer>
          <Logo src="/Logo.svg" alt="Logo" />
          RateSmart
        </LogoContainer>
        <Menu>
          <MenuTitle>Main Menu</MenuTitle>
          <NavItems>
            <ComingSoonMenuItem>
              <FaTachometerAlt />
              Dashboard
              <ComingSoonOverlay>Coming Soon</ComingSoonOverlay>
            </ComingSoonMenuItem>
            <MenuItem to="/review-manager" onClick={handleLinkClick}>
              <FaStar />
              Review Manager
            </MenuItem>
            <MenuItem to="/settings" onClick={handleLinkClick}>
              <FaCog />
              Settings
            </MenuItem>
            <MenuItem as="div" onClick={handleLogout}>
              <FaSignOutAlt />
              Log Out
            </MenuItem>
          </NavItems>
        </Menu>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
